
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

interface RootElementProps {
    children: React.ReactNode;
}
const queryClient = new QueryClient();

const RootElement: React.FC<RootElementProps> = ({ children }: { children: React.ReactNode }) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default RootElement;